<template>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>I-Care</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="4" md="4" lg="4">
                    <b-form-group>
                        <template v-slot:label>
                            No. BPJS
                        </template>
                        <b-form-input
                            type="text"
                            v-model="no_bpjs"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button variant="primary" class="mt-3">Simpan</b-button>
                    </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                    <iframe width="100%" height="700" :allowfullscreen="true" :src="src" title="W3Schools Free Online Web Tutorials"></iframe>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "icare",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        is_data: "",
        src: "https://www.google.com",
        tableBusy: false,
      };
    },
    computed: {
      
    },
    mounted() {
      this.getDatas();
    },
    methods: {
      async getDatas() {
        this.tableBusy = true;
        let etnis = await this.$axios.post("/ms_alergi/list");
  
        this.items = etnis.data.data;
        for (let i = 0; i < this.items.length; i++) {
          let x = this.items[i];
          x.no = i + 1;
        }
        this.totalRows = this.items.length;
        this.tableBusy = false;
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      }
    },
  };
  </script>
  